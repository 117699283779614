/** @format */

import { useNavigate } from "react-router-dom";
import { mintNFT } from "../../utils/mintApi";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import Button from "../../components/button/Button";
import toast from "react-hot-toast";
import { createAAWallet } from "../../utils/mintApi";
import { CreateNFTForm } from '../../components/Form/CreateNFTForm';
import InputV2 from "../../components/Common/InputV2";
import axios from 'axios';
import { BodyText, Caption, Paragraph } from "../../components/Common/Text";
import { ExclamationIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";

export const Mint = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("https://placehold.co/600x600"); 
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const { value, setIsAuthenticated, setValue } = useContext(AppContext);

  const createAndSetAAWallet = async () => {
    const AAwallet = await createAAWallet();
    await AAwallet?.getAddress();
    setIsAuthenticated(true);
    localStorage.setItem("isUserConnected", "true");
    setValue(AAwallet);
    return AAwallet;
  };

  const tryMintingNFT = async (wallet: any) => {
    try {
      const metadata = {
        name: title, // Use the title from the state
        image: imageUrl, // Use the imageUrl from the state
        description: description,
      };

      const minted = await mintNFT(wallet, metadata);
      if (minted) {
        toast.success(`NFT Minted Successfully`);
        navigate("/wallet");
      }
    } catch (error) {
      toast.error(`Error occurred during mint: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const mint = async () => {
    setLoading(true);
    let hasError = false;

    // Reset errors
    setTitleError("");
    setDescriptionError("");

    // Validate inputs
    if (!title.trim()) {
      setTitleError("Title is required.");
      hasError = true;
    }

    if (!description.trim()) {
      setDescriptionError("Description is required.");
      hasError = true;
    }

    // If there's an error, stop the minting process
    if (hasError) {
      setLoading(false);
      return;
    }
    if (value === undefined) {
      try {
        const wallet = await createAndSetAAWallet();
        if (wallet !== undefined) {
          await tryMintingNFT(wallet);
        }
      } catch (error) {
        toast.error(`Error occurred during account verification`);
      } finally {
        setLoading(false);
      }
    } else {
      await tryMintingNFT(value);
    }
  };

  const updateImage = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.unsplash.com/photos/random', {
        headers: {
          'Authorization': 'Client-ID TvrqNJ_nzHhnarmwesK7TKWhtIRd-33cHPhgXyF3el0'
        }
      });
      setImageUrl(response.data.urls.regular);
    } catch (error) {
      toast.error(`Error occurred while fetching image: ${error}`);
    } finally {
      setLoading(false);
    }
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

return (
  <>
    <div className="flex flex-col md:flex-row justify-center !p-[2rem] md:p-0 w-full">

      {/* Right Column */}
      <div className="flex flex-col w-full md:w-[25rem] p-[1rem] md:p-[1rem] rounded-lg border border-[#E7E9ED] bg-[#FFF] mb-[2rem] md:mb-0 md:mr-[1rem]">
        <img
          src={imageUrl}
          alt="Mint"
          className="rounded-lg w-full h-[333px] object-cover mt-2 px-2"
        />
        <div className="mt-4 md:mt-3 px-2">
          <Button
            type="secondary"
            loading={loading}
            onClick={updateImage}
            className="h-[2.75rem] w-full text-[#20343E] font-semibold text-base rounded-lg py-2 px-4 border border-[#E7E9ED] bg-[#FFF]"
          >
            Generate
          </Button>
        </div>
        <div className="flex flex-col items-start md:mt-6 px-2">
          <p className="text-xl text-[#20343E] font-bold">{title || "Title"}</p>
          <p className="text-sm text-[#67797F] font-normal pt-2 pb-2">{description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at consequat sem. Praesent eleifend dui ut justo vulputate, a imperdiet massa dictum."}</p>
        </div>
      </div>

      <div className="flex w-full md:w-[45rem] h-fit flex-col items-center rounded-lg border border-[#E7E9ED] bg-[#FFF]">
        <div className="flex flex-col items-start w-full p-[1rem] md:p-[2rem]">
          <h1 className="text-2xl font-bold text-[#20343E]">Create An NFT</h1>
          <p className="text-base font-normal text-[#67797F] mt-2">Create an NFT and earn $Art by selling them.</p>
          
          {/* Name Field */}
          <div className="w-full mt-6">
            <InputV2
              label="Name"
              description="Enter the name of your NFT"
              placeholder="e.g., Cute Ninjas"
              value={title}
              onChange={handleTitleChange}
              errorMessage={titleError}
            />
          </div>

          <div className="w-full mt-4">
            <label htmlFor="nft-description" className="block text-sm font-medium text-[#000] mb-2">
              Description
            </label>
            <p className="mt-1 text-sm text-gray-700 mb-3">
              Enter the Description of your NFT that you’d like to list.
            </p>
            <textarea
              id="nft-description"
              rows={4}
              className={`shadow-sm mt-1 block w-full sm:text-sm border rounded-md focus:ring-0 focus:border-gray-300 p-4`}
              placeholder="e.g., This piece represents..."
              value={description}
              onChange={handleDescriptionChange}
            />
            {descriptionError && (
              <div className="flex space-x-1.5 mt-3">
                  <ExclamationIcon className="text-error-dark w-[18px] h-[18px]" />
                  <Paragraph className="text-xs text-error-dark">{descriptionError}</Paragraph>
              </div>
            )}
          </div>

          <Button
              type="primary"
              loading={loading}
              onClick={mint}
              className="w-full text-[#FFF] font-semibold h-[2.625rem] py-0.5 px-1.125 flex justify-center items-center rounded-md bg-[#278272] shadow-md mt-4">
              Mint
          </Button>
          <p className="mt-1 text-sm text-[#67797F] mt-4 mb-0">
              Once you create this NFT, it would be automatically listed.
            </p>
        </div>
      </div>

    </div>

    <div className="flex flex-col md:flex-row justify-center !p-[2rem] md:p-0 w-full">

    </div>
  </>
);
};
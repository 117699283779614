import { APIClient, AbstractWallet, getOrCreateWalletJWT, checkAuthState, signIn } from '@crossmint/wallet-sdk';
import { ethers } from 'ethers';
import { CrossmintAPIClient } from '../apiClient/MyApiClient';
import transferAbi from './erc20tokenSell.json';
import tokenAbi from './tokenAbi.json';
import { Blockchain, NetworkType } from "@crossmint/wallet-sdk/lib/utils/blockchainUtils";
import axios from 'axios';

export const createAAWallet = async () => {
    try {
        let jwt: any
        jwt = await checkAuthState();
      if (jwt === undefined) {
        jwt = await signIn();
      } 
      const apiClient: APIClient = new CrossmintAPIClient();
      const web3authOptions = {
        chain: Blockchain.POLYGON_MUMBAI,
        network: "testnet" as NetworkType,
        rpcTargetUri: "https://rpc-mumbai.maticvigil.com",
        w3AuthClientId: process.env.REACT_APP_W3A_CLIENT_ID!,
        clientId: 'BJ5EEOj-5zE2ISGpqRcALloFsGdrQUL2c7iu77IHOvz-Gs1irBwigsVShTMMbXePeiNBMMQx9qOwbSN3JVdDQrc',
        displayName: 'Polygon Mumbai',
        blockExplorer: 'https://mumbai.polygonscan.com/',
        ticker: 'MATIC',
        tickerName: "Polygon Mumbai",
        email: 'email'
      };
      
      const result = await getOrCreateWalletJWT(apiClient, web3authOptions, jwt);
      return result;
    } catch (error) {
      console.error('Error:', error);
      throw error; // relanza el error para que pueda ser manejado por el código que llama a createAAWallet
    }
  };
  
export const transferTokenERC20 = async(aaWallet: AbstractWallet, to: string, rawAmount: string) => {
    const contractAddress = "0x4f365844f29458ca00e56a217a0f5bd77f870866"
    const contractAbi = transferAbi;
    const contract = new ethers.Contract(contractAddress, contractAbi, aaWallet!.zdProvider);
    try {
        const amountInWei = ethers.utils.parseUnits(rawAmount, 18);
        
        const signer = aaWallet!.zdProvider.getSigner();
        const contractWithSigner = contract.connect(signer);
        const transaction = await contractWithSigner.functions.transfer(to, amountInWei);

        const txBundle = await transaction.wait();
        const transactionHash = transaction.hash;

        return true;
    } catch (error) {
        console.error('Error transfering token:', error);
        return false;
    }
}

    export const mintNFT = async (aaWallet: AbstractWallet, metadata : any) => {
      console.log('Minting NFT', aaWallet.address);

      if (!aaWallet.address) {
        console.error('Wallet address is undefined');
        return false;
      }

      try {
        const apiClient = new CrossmintAPIClient();

        // Use the metadata passed as a parameter
        const result = await apiClient.mintNFT(aaWallet.address, metadata);
        console.log('NFT minted successfully:', result);
        return true;
      } catch (error) {
        console.error('Error minting NFT:', error);
        return false;
      }
    };

export const sellNFT = async (aaWallet : AbstractWallet, tokenId : number, contractAddress : string) => {
    const toAddress = "0x961fbefadee5ccf6a46d3293663fb3e65a2f6c21";
    const contractAbi = [
        {
            "constant": false,
            "inputs": [
                {
                    "name": "from",
                    "type": "address"
                },
                {
                    "name": "to",
                    "type": "address"
                },
                {
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "safeTransferFrom",
            "outputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ];

    const contract = new ethers.Contract(contractAddress, contractAbi, aaWallet.zdProvider);

    try {
        const signer = aaWallet.zdProvider.getSigner();
        const contractWithSigner = contract.connect(signer);
        const signerAddress = await signer.getAddress();

        const transaction = await contractWithSigner.safeTransferFrom(signerAddress, toAddress, tokenId);

        await transaction.wait();
        await transferTokens(signerAddress, 50);
        console.log('NFT transferred. Tx hash:', transaction.hash);
        return true;
    } catch (error) {
        console.error('Error transferring NFT:', error);
        return false;
    }
}

// export const sellNFT = async (aaWallet : AbstractWallet, tokenId : number) => {
//     const contractAddress = "0xdeC9cb5f89d553EafD69618d0C3eeE36B4Db517A";
//     const contractAbi = [
//         {
//             "constant": false,
//             "inputs": [
//                 {
//                     "name": "from",
//                     "type": "address"
//                 },
//                 {
//                     "name": "to",
//                     "type": "address"
//                 },
//                 {
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "safeTransferFrom",
//             "outputs": [],
//             "payable": false,
//             "stateMutability": "nonpayable",
//             "type": "function"
//         }
//     ];

// const contract = new ethers.Contract(contractAddress, contractAbi, aaWallet.zdProvider);

//     try {
//         const signer = aaWallet.zdProvider.getSigner();
//         const contractWithSigner = contract.connect(signer);
//         const signerAddress = await signer.getAddress();

//         // Estimate the gas limit or set it to a known safe value
//         const safeGasLimit = ethers.BigNumber.from("1000000");

//         const transaction = await contractWithSigner.safeTransferFrom(signerAddress, "0x6BA7b23D010de7F79AC7A6C3932eA1866D83D591", tokenId, { gasLimit: safeGasLimit });

//         await transaction.wait();

//         //Once successful, transfer tokens to the seller.
//         // await transferTokens(signerAddress, 100);
//         console.log('NFT transferred. Tx hash:', transaction.hash);
//         return true;

//     } catch (error) {
//         console.error('Error transferring NFT:', error);
//         return false;
//     }
// }

export const transferTokens = async (toAddress : string, tokenAmount : number) => {
    const contractAbi = tokenAbi;

    if (!ethers.utils.isAddress(toAddress)) {
        console.error('Invalid wallet address.');
        return false;
    }

    const provider = new ethers.providers.JsonRpcProvider('https://rpc-mumbai.maticvigil.com/v1/61494b19e2fc768debd71d9b5ed4613b4c44f199');

    const from = '0x8390d495511679eA3Ca194c4A8838e70E6450B11';
    const privateKey = '7ea395626ca8f8003154264c443bdd3313916dac01474ddef0c415be0bd2ec41';
    const contractAddress = '0xB26493413D317B80420dC8E174d4071Ba99C32F0';

    // Convert the token amount to the correct decimal places
    const decimals = 18;
    const amount = ethers.utils.parseUnits(tokenAmount.toString(), decimals);

    // Create a new wallet instance
    const wallet = new ethers.Wallet(privateKey, provider);
    
    // Create a contract instance
    const contract = new ethers.Contract(contractAddress, contractAbi, wallet);

    try {
        const transaction = await contract.transfer(toAddress, amount);
        const receipt = await transaction.wait();

        console.log('Transaction successful with hash:', receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error('Error sending transaction:', error);
        return false;
    }
}


// export const sellNFTOld = async (aaWallet: AbstractWallet, tokenId: number) => {

//     console.log("Selling");

//     const contractAddress = '0x6BA7b23D010de7F79AC7A6C3932eA1866D83D591';
//     const contractAbi = [
//         {
//             "inputs": [],
//             "stateMutability": "nonpayable",
//             "type": "constructor"
//         },
//         {
//             "anonymous": false,
//             "inputs": [
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "owner",
//                     "type": "address"
//                 },
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "approved",
//                     "type": "address"
//                 },
//                 {
//                     "indexed": true,
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "Approval",
//             "type": "event"
//         },
//         {
//             "anonymous": false,
//             "inputs": [
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "owner",
//                     "type": "address"
//                 },
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "operator",
//                     "type": "address"
//                 },
//                 {
//                     "indexed": false,
//                     "internalType": "bool",
//                     "name": "approved",
//                     "type": "bool"
//                 }
//             ],
//             "name": "ApprovalForAll",
//             "type": "event"
//         },
//         {
//             "anonymous": false,
//             "inputs": [
//                 {
//                     "indexed": false,
//                     "internalType": "uint256",
//                     "name": "_fromTokenId",
//                     "type": "uint256"
//                 },
//                 {
//                     "indexed": false,
//                     "internalType": "uint256",
//                     "name": "_toTokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "BatchMetadataUpdate",
//             "type": "event"
//         },
//         {
//             "anonymous": false,
//             "inputs": [
//                 {
//                     "indexed": false,
//                     "internalType": "uint256",
//                     "name": "_tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "MetadataUpdate",
//             "type": "event"
//         },
//         {
//             "anonymous": false,
//             "inputs": [
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "previousOwner",
//                     "type": "address"
//                 },
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "newOwner",
//                     "type": "address"
//                 }
//             ],
//             "name": "OwnershipTransferred",
//             "type": "event"
//         },
//         {
//             "anonymous": false,
//             "inputs": [
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "from",
//                     "type": "address"
//                 },
//                 {
//                     "indexed": true,
//                     "internalType": "address",
//                     "name": "to",
//                     "type": "address"
//                 },
//                 {
//                     "indexed": true,
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "Transfer",
//             "type": "event"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "to",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "approve",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "owner",
//                     "type": "address"
//                 }
//             ],
//             "name": "balanceOf",
//             "outputs": [
//                 {
//                     "internalType": "uint256",
//                     "name": "",
//                     "type": "uint256"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "getApproved",
//             "outputs": [
//                 {
//                     "internalType": "address",
//                     "name": "",
//                     "type": "address"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "owner",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "address",
//                     "name": "operator",
//                     "type": "address"
//                 }
//             ],
//             "name": "isApprovedForAll",
//             "outputs": [
//                 {
//                     "internalType": "bool",
//                     "name": "",
//                     "type": "bool"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [],
//             "name": "name",
//             "outputs": [
//                 {
//                     "internalType": "string",
//                     "name": "",
//                     "type": "string"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [],
//             "name": "owner",
//             "outputs": [
//                 {
//                     "internalType": "address",
//                     "name": "",
//                     "type": "address"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "ownerOf",
//             "outputs": [
//                 {
//                     "internalType": "address",
//                     "name": "",
//                     "type": "address"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [],
//             "name": "renounceOwnership",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "to",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "string",
//                     "name": "uri",
//                     "type": "string"
//                 }
//             ],
//             "name": "safeMint",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "from",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "address",
//                     "name": "to",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "safeTransferFrom",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "from",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "address",
//                     "name": "to",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 },
//                 {
//                     "internalType": "bytes",
//                     "name": "data",
//                     "type": "bytes"
//                 }
//             ],
//             "name": "safeTransferFrom",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "sell",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [],
//             "name": "sellToken",
//             "outputs": [
//                 {
//                     "internalType": "contract USDTTest",
//                     "name": "",
//                     "type": "address"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "operator",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "bool",
//                     "name": "approved",
//                     "type": "bool"
//                 }
//             ],
//             "name": "setApprovalForAll",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "bytes4",
//                     "name": "interfaceId",
//                     "type": "bytes4"
//                 }
//             ],
//             "name": "supportsInterface",
//             "outputs": [
//                 {
//                     "internalType": "bool",
//                     "name": "",
//                     "type": "bool"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [],
//             "name": "symbol",
//             "outputs": [
//                 {
//                     "internalType": "string",
//                     "name": "",
//                     "type": "string"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "tokenURI",
//             "outputs": [
//                 {
//                     "internalType": "string",
//                     "name": "",
//                     "type": "string"
//                 }
//             ],
//             "stateMutability": "view",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "from",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "address",
//                     "name": "to",
//                     "type": "address"
//                 },
//                 {
//                     "internalType": "uint256",
//                     "name": "tokenId",
//                     "type": "uint256"
//                 }
//             ],
//             "name": "transferFrom",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         },
//         {
//             "inputs": [
//                 {
//                     "internalType": "address",
//                     "name": "newOwner",
//                     "type": "address"
//                 }
//             ],
//             "name": "transferOwnership",
//             "outputs": [],
//             "stateMutability": "nonpayable",
//             "type": "function"
//         }
//     ];
//     const contract = new ethers.Contract(contractAddress, contractAbi, aaWallet!.zdProvider);

//     try {
//         const signer = aaWallet!.zdProvider.getSigner();
//         const contractWithSigner = contract.connect(signer);

//         const transaction = await contractWithSigner.sell(tokenId);

//         await transaction.wait();
//         const transactionHash = transaction.hash;

//         console.log('NFT sold. Tx hash:', transactionHash);
//         return true;
//     } catch (error) {
//         console.error('Error selling NFT:', error);
//         return false;
//     }
// }


export const walletContent = async (address: string) => {
    try {
        const chain = "polygon";
        const apiClient: CrossmintAPIClient = new CrossmintAPIClient();
    
        const result: Array<any> = await apiClient.fetchNFTs(address, chain);
        return result;
    } catch (error) {
        console.log(error);
    }

}


export const getTokenBalances = async(address: string) => {
    const fetchURL = `https://polygon-mumbai.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`;

    const raw = JSON.stringify({
        "jsonrpc": "2.0",
        "method": "alchemy_getTokenBalances",
        "headers": {
            "Content-Type": "application/json"
        },
        "params": [
            address,
            "erc20"
          ],
        "id": 1
    });

    const requestOptions: RequestInit = {
        method: 'POST',
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(fetchURL, requestOptions);
        const resJson = await response.json();
        console.log(JSON.stringify(resJson, null, 2));
        return resJson;
    } catch (error) {
        console.log(error);
    }
}

export const getTokenMetadata = async(address: string) => {
    const fetchURL = `https://polygon-mumbai.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`;

    const raw = JSON.stringify({
        "id": 1,
        "jsonrpc": "2.0",
        "method": "alchemy_getTokenMetadata",
        "params": [
          address
        ]
      });

    const requestOptions: RequestInit = {
        method: 'POST',
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(fetchURL, requestOptions);
        const resJson = await response.json();
        console.log(JSON.stringify(resJson, null, 2));
        return resJson;
    } catch (error) {
        console.log(error);
    }
}

export const hexBalanceToDecimalValue = (hexBalance: string) => {
    return ethers.utils.formatEther(hexBalance);
}
import { APIClient } from "@crossmint/wallet-sdk";
import { StoreAbstractWalletInput, TransferInput } from "@crossmint/wallet-sdk/src/types/APIClient";

// Your Crossmint API keys are extremely sensitive, so cannot be used directly from the frontend.
// If leaked, it can lead to loss of NFTs and funds from your users, and large costs to you due to API usage.
// Instead, you will need to create backend APIs that will proxy requests to Crossmint.
// These requests themselves should employ a user-based auth to determine validity of requests.
export class CrossmintAPIClient implements APIClient {
  private baseUri = process.env.REACT_APP_API_URL;

  async getSessionKey(address: string) {
    const body = JSON.stringify({ address });
    return this.fetch("wallets/sessionkey", { body, method: "POST" });
  }

  async storeAbstractWallet(input: StoreAbstractWalletInput) {
    const body = JSON.stringify(input);
    return this.fetch("wallets", { body, method: "POST" });
  }

  async transferNFT(input: TransferInput) {
    const body = JSON.stringify(input);
    return this.fetch("transfer", { body, method: "POST" });
  }

  async mintNFTStatus(id: string) {
    return this.fetch(`mintNFT/status?id=${id}`);
  }

  async fetchNFTs(address: string, chain : string) {
    return this.fetch(`wallets/${chain}:${address}/nfts`);
  }

  async mintNFT(recipient: string, metadata: { name: string; image: string; description: string }) {
    const body = JSON.stringify({
      recipient: `polygon:${recipient}`,
      metadata,
    });
    return this.fetch("mintNFT", { body, method: "POST" });
  }

  private async fetch(endpoint: string, options: { body?: string; method: string } = { method: "GET" }) {
    const url = `${this.baseUri}/${endpoint}`;
    const { body, method } = options;
    const response = await fetch(url, {
      ...(body ? { body } : {}),
      method,
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
    const json = await response.json();
    return json;
  }

  checkVersion(address: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  updateWallet(address: string, enableSig: string, version: number): Promise<any> {
    throw new Error("Method not implemented.");
  }
}

import { AbstractWallet } from "@crossmint/wallet-sdk";
import React, { createContext, useState, useEffect } from "react";

type AppContextData = {
  value: AbstractWallet | undefined;
  setValue: (value: AbstractWallet | undefined) => void;
  isAuthenticated: boolean | null;
  setIsAuthenticated: (value: boolean | null) => void;
  transferSuccess: boolean;
  setTransferSuccess: (value: boolean) => void;
  soldNft: boolean;
  setSoldNft: (value: boolean) => void;
};

const AppContext = createContext<AppContextData>({
  value: undefined,
  setValue: () => {},
  isAuthenticated: null,
  setIsAuthenticated: () => {},
  transferSuccess: false,
  setTransferSuccess: () => {},
  soldNft: false,
  setSoldNft: () => {},
});

const AppProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [value, setValue] = useState<AbstractWallet | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [transferSuccess, setTransferSuccess] = useState(false);
  const [soldNft, setSoldNft] = useState(false);

  useEffect(() => {
    // Initialize authentication from local storage
    const isUserConnected = localStorage.getItem("isUserConnected");
    if (isUserConnected === "true") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [value]);


  const contextValue: AppContextData = {
    value,
    setValue,
    isAuthenticated,
    setIsAuthenticated,
    transferSuccess,
    setTransferSuccess,
    soldNft,
    setSoldNft,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppProvider };
/** @format */

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { createAAWallet } from "../../utils/mintApi";
import { ReactComponent as UserIcon } from "../../assets/icons/user_plus.svg";
import { ReactComponent as BoltIcon } from "../../assets/icons/bolt_icon.svg";
import { ReactComponent as WalletIcon } from "../../assets/icons/wallet_icon.svg";
import { toast } from "react-hot-toast";
import Button from "../../components/button/Button";
import landingBackground from "../../assets/images/background.svg";
import {
  Caption,
  MarginLessSecondaryTitle,
  Paragraph,
  ParagraphBold,
  TerciaryTitle,
} from "../../components/Common/Text";

const StepCard = ({ Icon, title, subtitle }: any) => {
  return (
    <div className="flex flex-col py-5 items-start space-y-4 self-stretch w-full md:w-1/3 px-2">
      <div className="p-4 border border-[#278272] rounded-xl">
        <Icon className="[&>path]:stroke-[#278272] [&>path]:stroke-[1.5] h-[1.5rem] w-[1.5rem]" />
      </div>
      <MarginLessSecondaryTitle className="max-h-[4rem]">
        {title}
      </MarginLessSecondaryTitle>
      <TerciaryTitle className="!font-normal !text-[#67797F] !mt-2">
        {subtitle}
      </TerciaryTitle>
    </div>
  );
};

export const Login = () => {
  const { setValue, setIsAuthenticated } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = async () => {
    setLoading(true);
    const AAwallet = await createAAWallet();
    await AAwallet?.getAddress();
    setLoading(false);
    if (!AAwallet) {
      toast.error(`Error occurred during account register`);
      return;
    } else {
      toast.success(`Login Successfully`);
    }
    setIsAuthenticated(true);
    localStorage.setItem('isUserConnected', 'true')
    setValue(AAwallet);
    navigate("/mint");
  };

  return (
    <div className="flex flex-col items-center justify-center">
{/*<div
  className="absolute top-0 left-0 right-0 bottom-0 bg-center bg-no-repeat bg-cover z-0"
  style={{
    backgroundImage: `url(${landingBackground})`,
  }}
></div>*/}
      <div
        className="absolute top-0 left-0 w-full bg-center bg-no-repeat bg-cover z-0"
        style={{
          backgroundImage: `url(${landingBackground})`,
          backgroundPosition:
            window.innerWidth < 640 ? "center" : "center -5.5rem",
          height: window.innerWidth < 640 ? "5rem" : "65%",
        }}
      ></div>

      <div className="relative z-10 flex flex-col items-start md:w-[65rem] w-full h-full p-[3rem] md:px-12 px-4 space-y-4 md:border md:border-[#E7E9ED] border-t-0 border-l-0 border-r-0 bg-white md:rounded-lg shadow-sm overflow-y-auto flex-grow">
        <div>
          <Paragraph className="text-[#278272] font-normal border border-[#278272] rounded-full px-4 py-2">
            Demo
          </Paragraph>
        </div>
        <ParagraphBold className="text-[#20343E] !text-4xl font-bold">
          CreatorPad
        </ParagraphBold>
        <TerciaryTitle className="text-[#20343E] !font-normal max-w-[40rem]">
          CreatorPad offers you a seamless, gasless, and secure entry into the realm of NFTs. Say goodbye to complexity and hello to convenience with just a few clicks!
        </TerciaryTitle>

        <Button
          loading={loading}
          type="primary"
          className="flex flex-col justify-center items-center !mt-[2rem] !mb-[1rem] w-[13.125rem] h-[3rem] px-[1.125rem] bg-[#278272] rounded-lg shadow-md text-base text-[#FFF] font-semibold"
          onClick={login}
        >
          Get started!
        </Button>

        <div className="border-b border-[#E7E9ED] w-full"></div>

        <div className="flex flex-col md:flex-row py-2 items-start self-stretch !mb-[4rem]">
          <StepCard
            Icon={UserIcon}
            title="Effortless Account Creation"
            subtitle="Jumpstart your journey with our intuitive wallet setup. Enjoy the simplicity of social and email sign-ins—no crypto knowledge needed."
          />
          <StepCard
            Icon={BoltIcon}
            title="Create & Sell NFTs"
            subtitle="Leverage Crossmint’s robust services to craft your NFTs without worrying about gas. Turn your art into assets today & start earning."
          />
          <StepCard
            Icon={WalletIcon}
            title="Secure & Simple NFT Management"
            subtitle="Our unique architecture ensures NFTs are never at risk of loss while
          remaining non-custodial."
          />
        </div>

        <div className="mt-auto flex flex-col md:flex-row justify-between items-center w-full">
          <Paragraph className="text-[#67797F] mb-2 md:mb-0">
            Copyright © 2023 Crossmint. All rights reserved
          </Paragraph>
          <div className="flex flex-row items-center space-x-2 text-sm">
            <Paragraph className="font-medium text-[#67797F]">
              A demo powered by Crossmint
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

import { AppRouter } from "./Router";
import { AppProvider } from "./AppContext";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <AppProvider>
      <>
        <Toaster position="top-right"/>
        <AppRouter />
      </>
    </AppProvider>
  );
}

export default App;
